import { useState, useEffect } from 'react';
import { VFlex } from '../components/VFlex.jsx';
import { Button, Header, Modal, Icon, } from 'semantic-ui-react';
import { getPrice, getNextPrice } from '../util.js';
import Countdown from './countdown.jsx';

export default function InfoModal({ close, color }) {
  const price = getPrice();
  const showPrice = Math.round(price / 1e6);
  const [nextTime, nextPrice] = getNextPrice(price);

  return <VFlex className="infomodal">
    <Header style={{marginTop: '3px', alignSelf: 'center', display: 'flex', justifyContent: 'center', width: 'calc(100% - 45px)'}} content="PUPGRADE INFO" />
    <Button icon="close" style={{position: 'absolute', top: '10px', right: '10px', backgroundColor: 'rgba(255,255,255,0.45)'}} onClick={close} />
    <div className="modalgradient"></div>
    <Icon color={color} size="large" name="magic" />
    <p>Pixel pups can mint a goodboi with the same traits.</p>
    <Icon color={color} size="large" name="heart" />
    <p>You get to keep your pup!<br/>They are not burned during pupgrades.</p>
    { price ? <>
    <Icon color={color} size="large" name="dollar sign" />
        <p>{nextTime ? "Current" : "Pupgrade" } cost: {showPrice} ALGO</p>
    { nextTime ? <>
        <Icon color={color} size="large" name="chart line" />
        <p>Price increase in:<br/><Countdown to={nextTime} /><br/><br/>Next price: {Math.floor(nextPrice/1e6)} ALGO</p>

      </> : null }
    </>
      : <><Icon color={color} size="large" name="clock outline"/>
        <p>
          Pupgrades start in:<br />
          <Countdown to={nextTime} />
        </p>
      </>
    }
  </VFlex>
}
