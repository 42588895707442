import { useEffect, useState } from 'react';

function timeDiff(from) {
    let s = '';
    const now = Date.now();
    const diff = from-now;
    if (diff <= 0.999) {
      return 'NOW';
    }
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const secs = Math.floor((diff % (1000 * 60)) / 1000);
    if (days) {
      s += `${days} DAY`;
      if (days > 1) s += 'S';
    }
    if (hours) {
      s += ` ${hours} hour`
      if (hours > 1) s += 'S';
    }
    if (mins) {
      s += ` ${mins} min`
      if (mins > 1) s += 'S';
    }
    if (secs) {
      s += ` ${secs} sec`
      if (secs > 1) s += 'S';
    }
  return s.trim();
}

export default function Countdown({ to, onDone, }) {
  const [str, setStr] = useState('');

  useEffect(() => {
    const intv = setInterval(() => {
      setStr(timeDiff(to));
    }, 1000);
    setStr(timeDiff(to));

    const diff = to - Date.now();
    let tmt;
    if (diff > 0 && onDone) {
      console.log("onDone in", diff);
      tmt = setTimeout(() => onDone(), diff);
    }

    return () => {
      if (tmt)
        clearTimeout(tmt);
      clearInterval(intv);
    }
  }, [to, onDone]);

  return str;
}
