import { useState, useCallback, useEffect, useMemo, useContext, } from 'react';
import { useParams, useNavigate, } from "react-router-dom";
import { useWallet } from '@txnlab/use-wallet';
import backgrounds from '../backgrounds.json';
import traits from '../traits.json';
import Pup from '../components/pup.jsx';
import { getHolder } from '../algod.js';
import Address from '../components/Address.jsx';
import config from '../config.json';
import { Button, Popup, } from 'semantic-ui-react';
import OverlayContext from '../providers/Overlay.js';
import PupgradeModal from '../components/PupgradeModal.jsx';
import AssetsContext  from '../providers/Assets.js';
import { pupUnitToGoodboiUnit, goodboiUnitToAid, pupUnitToAid, serializeFilters } from '../util.js';
import pixelPupAids from '../pixel-pup-aids.json';
import { VFlex } from '../components/VFlex.jsx';
import useResponsive from '../hooks/use-responsive.js';

function You() {
  return <strong>You!&nbsp;</strong>;
  // return <div style={{position: 'absolute', animation: '160ms showslow forwards', opacity: 0, animationDelay: '300ms', transform: 'translate(-54%, -120%) rotate(8deg)' }}><strong>That's you!⤵</strong></div>
}

function Link({to, children}) {
  const navigate = useNavigate();
  const callback = useCallback(() => navigate(to), [to, navigate]);
  return <a className="afakelink" onClick={callback}>{children}</a>
}

export default function PupPage() {
  const [ bg, setBg ] = useState();
  const navigate = useNavigate();
  const { unit } = useParams();
  const { activeAccount } = useWallet();
  const [ error, setError ] = useState();
  const [ owner, setOwner ] = useState();
  const [ goodboiOwner, setGoodboiOwner ] = useState();
  const { setOverlay, setPos, setColor, } = useContext(OverlayContext);
  const { myPups, fetchMyPups, creatorBois } = useContext(AssetsContext);

  useEffect(() => {
    const u = pixelPupAids[unit];
    if (u) {
      navigate(`/pup/${u}`);
    } else if (!traits[unit]) {
      setError(`404 - Not a pixel pup: ${unit}`);
    }
  }, [unit]);

  useEffect(() => fetchMyPups(activeAccount?.address), [activeAccount?.address, fetchMyPups]);

  const num = useMemo(() => unit.replace(/PP0*/, ''), [unit]);

  const goodboiUnit = useMemo(() => pupUnitToGoodboiUnit(unit), [unit]);

  const assetId = useMemo(() => pupUnitToAid(unit), [unit]);

  const goodboiAid = useMemo(() => goodboiUnitToAid(goodboiUnit), [goodboiUnit]);

  const [width] = useResponsive();

  const pupWidth = useMemo(() => Math.min(500, width), [width]);

  useEffect(() => {
    (async() => {
      if (activeAccount?.address && myPups && myPups[assetId]) {
        setOwner(activeAccount.address);
      } else if (assetId) {
        setOwner();
        setOwner(await getHolder(assetId));
      }
    })()
  }, [activeAccount, myPups, assetId]);

  useEffect(() => {
    if (goodboiAid && creatorBois && creatorBois[goodboiAid]) {
      setGoodboiOwner(config.bois.address);
    } else {
      // TODO could optimize goodboi lookup by providing all assets
      (async () => {
        try {
          setGoodboiOwner();
          const goodboiOwner = await getHolder(goodboiAid);
          setGoodboiOwner(goodboiOwner);
        } catch(e) {
          setGoodboiOwner();
          console.error(e);
          setError("Something went wrong looking up goodboi owner: "+e.message);
        }
      })()
    }
  }, [goodboiAid, creatorBois, ]);

  const userIsOwner = owner && activeAccount?.address === owner;

  const canPupgrade = myPups && myPups[assetId] ? myPups[assetId].mintable : (
    goodboiOwner === undefined ? undefined : goodboiOwner === config.bois.address
  );

  useEffect(() => {
    const s = setTimeout(() => {
      if (traits[unit]) {
        setBg('#' + backgrounds[traits[unit].Background])
      } else {
        setBg('#' + backgrounds.Red);
      }
    }, 700);
    return () => clearTimeout(s);
  }, [unit]);

  const closeOverlay = useCallback(() => setOverlay(), [setOverlay]);

  const fmp = useCallback(() => fetchMyPups(activeAccount?.address), [activeAccount?.address]);

  const pupgradeCallback = useCallback(() => {
    window.scrollTo(0, 1500);
    setOverlay(<PupgradeModal bg={bg} assetId={assetId} goodboiAssetId={goodboiAid} pupNum={num} reloadData={fmp} close={closeOverlay} />);
    setPos('bottom');
    setColor(bg);
  }, [bg, assetId, closeOverlay, fetchMyPups, goodboiAid, num, setPos, setColor, setOverlay]);

  const navPixelPedia = (key, value) => `/pixel-pedia#${serializeFilters({[key]: value})}`;

  return error ? <VFlex>{error}</VFlex> : <VFlex style={{background: bg, borderTopLeftRadius: '25px', borderTopRightRadius: '25px', }}>
    <Pup width={pupWidth} unit={unit} show="flash" style={{borderRadius: '25px'}} outerStyle={{marginTop: '25px'}} include={<>
      <div style={{position: 'absolute', top: '-18px', left: '25px',}}>Pixel Pups #{num}</div>
      <div style={{position: 'absolute', top: '-18px', right: '25px',}}>{unit}</div>
      { !userIsOwner && canPupgrade ?
        <Popup position="right center" content="This pup can mint a goodboi!" trigger={<div className="big-mintable-badge">PUPGRADABLE!</div>} /> : null
      }
      { userIsOwner && canPupgrade ? <>
        <Popup position="bottom" content="PUPGRADE ME!" trigger={<div onClick={pupgradeCallback} className="fakelink big-mintable-badge">PUPGRADABLE!</div>} />
        <Popup position="bottom" content="PUPGRADE ME!" trigger={<div onClick={pupgradeCallback} className="fakelink big-mintable-badge-2"></div>} />
      </> : null }

    </>} />
    <div className="pp-tablediv">
      <div className="pup-page-flex">
        <table>
          <tbody>
            <tr><td className="fontbold" style={{textAlign:"center", paddingBottom: '10px',}} colSpan="2">INFO</td></tr>
            <tr><td>Name</td><td>Pixel Pups #{num}</td></tr>
            <tr><td>Asset ID</td><td><a rel="noreferrer" target="_blank" className="whitelink" href={`https://nftexplorer.app/asset/${assetId}`}>{assetId}</a></td></tr>
            <tr><td>Goodboi ID</td><td><a rel="noreferrer" target="_blank" className="whitelink" href={`https://nftexplorer.app/asset/${goodboiAid}`}>{goodboiAid}</a></td></tr>
            <tr><td>Owner</td><td style={{position: 'relative'}}>
              { owner ? <>
                {userIsOwner ? <You /> : null}<Address>{owner}</Address>
              </> : "Loading" }
            </td></tr>
            {
              goodboiOwner && goodboiOwner !== config.bois.address ?
                <tr>
                  <td>Goodboi Owner</td><td>{
                  goodboiOwner && goodboiOwner === activeAccount?.address ? <You /> : null 
                  }{
                    goodboiOwner === undefined ? null :
                      <Address>{goodboiOwner}</Address>
                  }</td>
                </tr>
                : null
            }
            <tr className="rainbow" style={{color: userIsOwner && canPupgrade ? 'rgba(128,128,0,1)' : 'inherit'}}><td>Pupgradable</td><td>{ canPupgrade === undefined ? "Loading" : canPupgrade ? (userIsOwner ? <span onClick={pupgradeCallback} className="fakelink whitelink rainbow">YES!</span> : <span>Yes</span>) : "No" }</td></tr>
            </tbody>
          </table>
        <table>
          <tbody>
            <tr><td className="fontbold" style={{textAlign:"center", paddingBottom: '10px',}} colspan="2">TRAITS</td></tr>
              { Object.entries(traits[unit] ?? {}).map(([key, value]) => <tr key={`${key}`}><td>{key}&nbsp;&nbsp;</td>
                  <td><Link to={navPixelPedia(key, value)}>{value}</Link></td></tr>) }
            </tbody>
          </table>
        </div>
      { userIsOwner && canPupgrade ?
        <Button onClick={pupgradeCallback} className="big pupgrade-btn">PUPGRADE</Button>
      : null }
    </div>
  </VFlex>;
}
