import { abbrev } from '../util.js';
import useNFD from '../hooks/useNFD.js';

export default function Address({ children }) {
  const address = typeof children === 'string' ? children : false;
  const NFD = useNFD(address);
  if (!address)
    return "N/A";
  const text = NFD ?? abbrev(address, true, 4);
  return <a rel="noreferrer" href={`https://explorer.perawallet.app/accounts/${address}/collectible-assets/`} target="_blank">{text}</a>;
}
