import { useState, useCallback } from 'react';
import { Button } from 'semantic-ui-react';

const copyTitle = 'COPY';

export default function Copyable({ value, style, text }) {
  const [title, setTitle] = useState(text ?? copyTitle);

  const copy = useCallback(() => {
    setTitle('COPIED');

    navigator.clipboard.writeText(String(value));

    setTimeout(() => {
      setTitle(text ?? copyTitle);
    }, 1_500);
  }, [value, text]);

  return <Button onClick={copy} style={style}>
    {title}
  </Button>;
}

