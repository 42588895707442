import { useState, useEffect, useCallback, } from "react";
import { useNavigate } from "react-router-dom";
import { Popup } from 'semantic-ui-react'
import { loadImage } from '../util.js';

export default function Pup({ unit, include, mintable, onLoad, showMintable, show, width='500px', className='', style, outerStyle, children}) {
  const navigate = useNavigate();
  const [s, setShow] = useState();

  useEffect(() => {
    setShow();
    loadImage(`/assets/images/pups/${unit}.webp`, (prog) => {
      if (prog === 1 || prog === -1) {
        setShow(show ?? true);
        if (onLoad)
          onLoad();
      }
    });
  }, [unit, onLoad, show]);

  return <div style={{width, height: width, position: 'relative', ...outerStyle }} onClick={() => navigate(`/pup/${unit}`)}>
    { s === 'flash' ? <div className="drawin"></div> : null }
    { showMintable && mintable ? <Popup position="top center" content="PUPGRADE ME!" trigger={<div className="mintable-badge"></div>} /> : null }
    { s ? <img alt={`pixel pup ${unit}`} src={`/assets/images/pups/${unit}.webp`} className={show && !s ? 'burnt' : className} style={{maxWidth: '100%', ...style}} /> : null }
    { children ? <div style={{position: 'absolute', zIndex: -1, top: 0, left: 0, right: 0, bottom: 0}}>
      {children}
    </div> : null }
    { include ? <div style={{position: 'absolute', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0}}>
      {include}
    </div> : null }
  </div>
}
