import { useState, useMemo, useCallback, createContext } from 'react';

const OverlayContext = createContext({});

export default OverlayContext;

const initColor = '#cce2ff';

export function OverlayProvider({ children }) {
  const [pos, setPos] = useState('top');
  const [color, setColor] = useState(initColor);
  const [overlay, setOverlay] = useState(null);

  const resetColor = useCallback(() => setColor(initColor), []);

  const value = useMemo(() => ({
    pos, setPos, overlay, setOverlay, color, setColor, resetColor,
  }), [pos, overlay, color, resetColor]);

  return <OverlayContext.Provider value={value}>
    {children}
  </OverlayContext.Provider>;
}
