import { useState, useEffect, useCallback, useContext, useMemo, } from 'react';
import { useNavigate, } from "react-router-dom";
import { useWallet } from '@txnlab/use-wallet';
import { abbrev } from '../util.js';
import { Button, Icon, Header, } from 'semantic-ui-react'
import { HFlex, VFlex } from './VFlex.jsx';
import OverlayContext from '../providers/Overlay.js';
import AssetsContext from '../providers/Assets.js';
import useNFD from '../hooks/useNFD.js';
import Pup from '../components/pup.jsx';
import useResponsive from '../hooks/use-responsive.js';
import useScrollToTop from '../hooks/use-scroll-top.js';

export function LoginModal({ close }) {
  const navigate = useNavigate();
  const { activeAccount, providers } = useWallet();
  const { myPups, fetchMyPups, } = useContext(AssetsContext);
  const NFD = useNFD(activeAccount?.address);

  const activeProvider = providers?.find(
    (provider) => provider.metadata.id === activeAccount?.providerId
  );

  useEffect(() => {
    if (activeAccount?.address) {
      fetchMyPups(activeAccount?.address);
    }
  }, [activeAccount?.address, fetchMyPups]);

  const [myPupUnits, numPupgradable] = useMemo(() => {
    if (myPups) {
      const values = Object.values(myPups)
      const units = values.map(({unit}) => unit);
      const numPupgradable = values.filter(({mintable}) => mintable).length
      return [units, numPupgradable];
    }
    return [[], 0];
  }, [myPups]);

  const [showPupId, setShowPupId] = useState(0);
  useEffect(() => {
    if (myPupUnits.length) {
      const timeout = setInterval(() => {
        setShowPupId(i => (i+1) % myPupUnits.length);
      }, 750);
      return () => clearTimeout(timeout);
    }
  }, [myPupUnits?.length, myPupUnits[0]]);

  const goMyPups = useCallback(() => { navigate('/my-pups'); close(); return false; }, [close, navigate]);

  return <VFlex>
    <Header style={{marginTop: '3px', alignSelf: 'center', width: 'calc(100% - 90px)', display: 'flex', justifyContent: 'center',}} icon={activeAccount ? "user circle" : "plug"} content={ activeAccount ? 'Connected: ' + abbrev(activeAccount?.address, 1, 8) : 'Connect Wallet' } />
        <Button icon="close" style={{position: 'absolute', top: '10px', right: '10px',}} onClick={close} />
        <HFlex style={{marginBottom: '20px'}} >
          { !myPupUnits.length ? <img alt="rainbow pup" src="/assets/images/rainbow.png" width="192" className="rainbow" />
          : <Pup unit={myPupUnits[showPupId]} width="192px" style={{borderRadius: '12px'}} /> }
          <VFlex style={{marginLeft: '25px'}}>
            { !activeAccount ?
                providers?.map((provider) => (
                  <div key={provider.metadata.id} style={{margin: '1rem'}}>
                    <Button color='black' fitted basic style={{  padding: '1px', overflow: 'hidden', display: 'flex', alignItems: 'center', width: '150px'}}
                      onClick={() => { close(); provider.connect(); } }
                    >
                      <img
                        width={48}
                        height={48}
                        alt={`${provider.metadata.name} icon`}
                        src={provider.metadata.icon}
                      />
                      <div style={{flexGrow: 1}}>
                        {provider.metadata.name}
                      </div>
                    </Button>
                  </div>
                )) : 
                <div>
                  { NFD ? <p>NFD: {NFD}</p> : null }
                  <p>Pixel Pups: {myPupUnits.length}</p>
                  <p>Pupgradable: {numPupgradable}</p>
                  { numPupgradable ? <Button onClick={goMyPups} style={{marginBottom: '8px'}} basic color="green">GO PUPGRADE!</Button> : null }

                </div>
            }
          </VFlex>
        </HFlex>
        { activeAccount ? 
          <Button color="red" onClick={activeProvider?.disconnect}>DISCONNECT</Button>
          : null }
      </VFlex>
}

export default function LoginButton({ id, label = '', size="medium", style, color="red", className, }) {
  const { activeAccount } = useWallet();
  const { setOverlay, setPos, resetColor, } = useContext(OverlayContext);
  const NFD = useNFD(activeAccount?.address);
  const [, wsize] = useResponsive();
  const scrollToTop = useScrollToTop();

  const showLoginOverlay = useCallback(() => {
    setPos('top');
    resetColor();
    setOverlay(<LoginModal close={() => setOverlay()} />);
    scrollToTop();
  }, [setOverlay, scrollToTop, resetColor, setPos]);

  return activeAccount ? ( wsize === 'l' ?
      <Button
        onClick={() => showLoginOverlay()}
        color="green"
        icon='user'
        label={{ pointing: 'right', color: 'green', content: NFD ?? abbrev(activeAccount?.address, 0, 8)}}
        labelPosition='left'
        style={style}
      /> :
      <Button
        onClick={() => showLoginOverlay()}
        icon='user'
        color="green"
        style={style}
      />
  ) : <Button id={id} className={className} basic style={style} size={size} color={color} icon onClick={() => showLoginOverlay()}><Icon name="plug" />{label}</Button>;
}
