import { useContext } from 'react';
import OverlayContext from '../providers/Overlay.js';
import { Sidebar, Segment, } from 'semantic-ui-react'
import Header from './Header.jsx';
import { Outlet } from "react-router-dom";
import Footer from './footer.jsx';

export default function Layout({ children, override, noheader, style, className, }) {
  const { overlay, pos, color } = useContext(OverlayContext);
  return <Sidebar.Pushable as={Segment} style={{ overflow: 'hidden' }}>
      <Sidebar
        as={Segment}
        direction="left"
        animation="scale down"
        visible={pos === "left" && !!overlay}
        style={{backgroundColor: color}}
      >
        {pos === "left" && overlay}
      </Sidebar>
      <Sidebar
        as={Segment}
        direction="bottom"
        animation="scale down"
        visible={pos === "bottom" && !!overlay}
        style={{backgroundColor: color}}
      >
        {pos === "bottom" && overlay}
      </Sidebar>
      <Sidebar
        as={Segment}
        direction="top"
        animation="scale down"
        visible={pos === "top" && !!overlay}
      >
        {pos === "top" && overlay}
      </Sidebar>
      <Sidebar.Pusher dimmed={!!overlay}>
        <div className={className} style={{display: 'flex', flexDirection: 'column',  maxWidth: '960px', left: '50%', position: 'relative', transform: 'translate(-50%, 0)', justifyContent: 'space-between', minHeight: '100svh', alignItems: 'stretch', ...style}}>
          { noheader ? null : <Header /> }
          { override ? children : <Outlet /> }
          <Footer />
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
}
