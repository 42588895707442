import GoodboiAids from './goodbois-aids.json';
import PupAids from './pixel-pup-aids.json';
import AllTraits from './all-traits.json';
import promilol from 'promilol';
import config from './config.json';

export function chunk(elems, num=20) {
  return elems.reduce((out, cur) => {
    let last = out[out.length - 1];
    if (last.length === num) {
      out.push([]);
      last = out[out.length -1];
    }
    last.push(cur);
    return out;
  }, [[]]);
}

export function abbrev(str = '', both = true, num = 6) {
  return str.slice(0, num) + '..' + (both ? str.slice(-num) : '');
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ALGO = 'Ⱥ';

// TODO now in several places, bring it here
// s/r: padStart
const pupUnitRegex = /^PP[0-9]+/

export const pupUnitToGoodboiUnit = (pupUnit) => {
  if (!pupUnitRegex.test(pupUnit)) {
    console.warn('Non pup unit given: '+pupUnit);
    return
  }
  const num = Number(pupUnit.replace('PP', ''));
  return numToGoodboiUnit(num);
}

export const pupUnitToAid = unit => {
  if (!unit) return unit;
  const found = Object.entries(PupAids).find(([aid, _unit]) => _unit === unit);
  return found && found[0];
}

export const goodboiUnitToAid = unit => {
  if (!unit) return unit;
  const found = Object.entries(GoodboiAids).find(([aid, _unit]) => _unit === unit);
  return found && found[0];
}

export const numToGoodboiUnit = num =>  num ? 'GB' + String(num).padStart(4, '0') : num;

export const numToPupUnit = num => num ? 'PP' + String(num).padStart(3, '0') : num;

export function deserializeFilters(str) {
  str = decodeURIComponent(str);
  return Object.fromEntries(
    str.split("&")
      .map(pair => pair.split(":"))
      .filter(([key, value]) => {
        return AllTraits[key];
      })
  );
}

export function serializeFilters(filters) {
  return Object.entries(filters).map(([key, value]) => `${key}:${value}`).join('&');
}

export const NFDCache = {};

// cachedLookupNFD
//  NFDCache -> promise || result
//  addresses -> NFDCache -> [existing, new]
//  if [new] -> NFDCache += promise
//  await Promise.all(values(NFDCache[addresses]))

export async function lookupNFD(addresses) {
  addresses = Array.isArray(addresses) ? addresses : [addresses];
  const New = []
  for(const address of addresses) {
    if (NFDCache[address] === undefined) {
      New.push(address);
    }
  }
  if (New.length) {
    const asyncRes = _lookupNFD(New);
    for(const N of New) {
      NFDCache[N] = asyncRes.then((data) => {
        return data[N];
      });
    }
  }
  const resultsE = Object.entries(NFDCache).filter(([key]) => addresses.includes(key));
  const results = {};
  for(const [resKey, resValue] of resultsE) {
    NFDCache[resKey] = results[resKey] = await resValue;
  }
  return results;
}

async function _lookupNFD(address) {
  // TODO cache not existing
  // TODO debounce / join requests
  let addresses = Array.isArray(address) ? address : [address];
  const results = Object.fromEntries(addresses.map(address => ([address, null])));
  const chunks = chunk(addresses, 20);
  await promilol(chunks, async chunk => {
    if (!chunk.length)
      return;
    const query = chunk.join('&address=');
    // console.log("Querying", ...chunk);
    const url = `https://api.nf.domains/nfd/lookup?address=${query}&view=thumbnail`;
    let text;
    try {
      const resp = await fetch(url);
      text = await resp.text();
      let json;
      if (!text.length) {
        return;
      }
      json = JSON.parse(text);
      for(const [addr, obj] of Object.entries(json)) {
        const { name } = obj;
        results[addr] = name;
      }
    } catch(e) {
      return;
    }
  }, { concurrency: 4 });
  return results;
}

const { price } = config.pup_v2s;

export function getPrice() {
  const now = Date.now();
  let currentPrice;
  for(const [timestampStart, _price] of Object.entries(price)) {
    if (now > timestampStart)
      currentPrice = _price;
  }
  if (!currentPrice) {
    return 0;
  }
  return currentPrice;
}

export function getNextPrice(fromPrice) {
  const entries = Object.entries(price);
  if (fromPrice < 1_000_000)
    fromPrice = 1e6 * fromPrice;
  const fromTimestamp = entries.find(([_, p]) => p === fromPrice);
  if (!fromTimestamp) {
    return entries[0];
  }
  const fromIndex = entries.indexOf(fromTimestamp);
  if (fromIndex < entries.length - 1) {
    return entries[fromIndex+1];
  }
  return [];
}

export function loadImage(imageUrl, onprogress) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    var notifiedNotComputable = false;

    xhr.open('GET', imageUrl, true);
    xhr.responseType = 'arraybuffer';

    xhr.onprogress = function(ev) {
      if (ev.lengthComputable) {
        onprogress(ev.loaded / ev.total);
      } else {
        if (!notifiedNotComputable) {
          notifiedNotComputable = true;
          onprogress(-1);
        }
      }
    }

    xhr.onloadend = function() {
      if (!xhr.status.toString().match(/^2/)) {
        reject(xhr);
      } else {
        onprogress(1);

        var options = {}
        var headers = xhr.getAllResponseHeaders();
        var m = headers.match(/^Content-Type:\s*(.*?)$/mi);

        if (m && m[1]) {
          options.type = m[1];
        }

        var blob = new Blob([this.response], options);

        resolve(window.URL.createObjectURL(blob));
      }
    }

    xhr.send();
  });
}
