export function VFlex({ children, style, className, }) {
  return <div className={className} style={{display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', ...style}}>
    {children}
  </div>;
}

export function HFlex({ children, style, grow, ...rest }) {
  return <div {...rest} style={{display: 'flex', flexGrow: grow, justifyContent: "center", alignItems: 'center', ...style}}>
    {children}
  </div>;
}



