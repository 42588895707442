import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useWallet } from '@txnlab/use-wallet';
import LoginButton from '../components/LoginButton.jsx';
import traits from '../traits.json';
import AssetsContext from '../providers/Assets.js';
import { pupUnitToGoodboiUnit } from '../util.js';
import backgrounds from '../backgrounds.json';
import { VFlex } from '../components/VFlex.jsx';
import Pup from '../components/pup.jsx';
import Layout from '../components/Layout.jsx';

function randomUnit(from) {
  const has = from && Object.values(from).length;
  if (!has) {
    const r = Math.floor(Math.random() * 1000)+1;
    return 'PP' + String(r).padStart(3, '0')
  } else {
    const len = Object.keys(from).length
    const ridx = Math.floor(Math.random() * len);
    return Object.values(from)[ridx].unit;
  }
}

export default function Home() {
  const navigate = useNavigate();
  const { activeAccount } = useWallet();
  const { myPups, fetchMyPups, } = useContext(AssetsContext);
  const [unit, setUnit] = useState(randomUnit(myPups));

  const goMyPups = useCallback(() => { navigate('/my-pups'); return false; }, [navigate]);
  const goPixelPedia = useCallback(() => { navigate('/pixel-pedia'); return false; }, [navigate]);

  useEffect(() => {
    const intv = setInterval(() => {
      setStep(0);
      setBubbleText();
      setUnit(randomUnit(myPups));
    }, 30_000);
    return () => clearInterval(intv);
  }, [myPups, setUnit]);

  useEffect(() => {
    if (activeAccount?.address && fetchMyPups) {
      fetchMyPups(activeAccount?.address);
    }
  }, [activeAccount?.address, fetchMyPups,]);


  const goodboiUnit = useMemo(() => pupUnitToGoodboiUnit(unit), [unit]);

  const bg = useMemo(() => traits && traits[unit] && '#' + backgrounds[traits[unit].Background], [unit]);

  const [step, setStep] = useState(0);
  const [bubbleText, setBubbleText] = useState();

  const pupLoaded = useCallback(() => {
    setStep(1)
    setTimeout(() => setStep(2), 3000);
    setTimeout(() => {
      setStep(3);
      const st = 750;
      [
      () => setBubbleText('...'),
      () => setBubbleText(''),
      () => setBubbleText('💔'),
      () => setBubbleText('💔'),
      () => setBubbleText(''),
      () => setBubbleText('...'),
      () => setBubbleText(''),
      () => setBubbleText('🙏'),
      () => setBubbleText('🧙'),
      () => setBubbleText('🙏'),
      () => setBubbleText('🪄'),
      () => setStep(4),
      () => { setBubbleText('!'); setStep(5) },
      () => setStep(6),
      () => {},
      () => setStep(7),
      () => { setStep(8);
        setBubbleText('❣️');
        setTimeout(() => setBubbleText('🙏'), 500);
        setTimeout(() => setBubbleText('🥰'), 1000);
        setTimeout(() => setBubbleText('🤩'), 1500);
      },
      () => {},
      () => {},
      () => {},
      () => setStep(9),
      ].forEach((fn, i) => setTimeout(fn, (i+1) * st));
    }, 7000);
  }, []);


  return <Layout className="colorin layout" noheader={1} override={1} style={{justifyContent: 'flex-start', alignItems: 'center', maxWidth: '100%', backgroundColor: bg}}>
    <div className="homegradient">
      <h1 className="h1-title" style={{color: bg}}>PIXEL<br/>PUPS</h1>
    </div>

    <div className={`home-images-container step-${step}`}style={{backgroundColor: bg}}>
      <div className="home-image-container homepup">
        <div className="homebubble">
          {bubbleText}
        </div>
        <Pup onLoad={pupLoaded} unit={unit} width="auto" style={{borderTopLeftRadius: '25px'}}/>
      </div>

      <div className="home-image-container homeboi" style={{borderTopRightRadius: '25px'}}>
        <div className="delay-2s"></div>
        <img alt="goodboi" src={`/assets/images/bois/${goodboiUnit}.png`} style={{transform: 'scale(-1.5, 1.5) translate(-4%, 1%)'}}/>
      </div>
    </div>

    <VFlex className="homegradientbottom" style={{flexGrow: 1}}>
      <div className="hometextflash delay-3s" style={{marginBottom: '2rem'}}>
            <div onClick={goMyPups} className="menu-row fakelink">
              <div className="menu-arrow"><img alt="eyes emoji" src="/assets/images/eyes.png" /></div>
              <div><h3>PUPGRADE</h3></div>
              <div className="menu-arrow"><img alt="eyes emoji" src="/assets/images/eyes.png" className="fliph" /></div>
            </div>

            <div onClick={goPixelPedia} className="menu-row fakelink">
              <div className="menu-arrow"><img  alt="eyes emoji" src="/assets/images/eyes.png" /></div>
              <div><h3>PIXELPEDIA</h3></div>
              <div className="menu-arrow"><img alt="eyes emoji" src="/assets/images/eyes.png" className="fliph" /></div>
            </div>
      </div>

      <VFlex className="hometextflash delay-4s">
        { activeAccount?.address ? null: <LoginButton size="huge" color="orange" className="rainbow white35bg" id="fuuuuck" label="CONNECT WALLET"/> }
      </VFlex>

    </VFlex>

  </Layout>;
}
