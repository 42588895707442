import { useState, useLayoutEffect } from 'react';

const threshold = 600;

export default function useResponsive() {
  const [width, setWidth] = useState(1920);
  const [size, setSize] = useState('l');
  useLayoutEffect(() => {
    function updateDimensions() {
      const size = window.innerWidth < threshold ? 's' : 'l';
      setSize(size);
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  return [width, size];
}
