import { useState, useEffect, useMemo, useCallback, createContext } from 'react';
import config from '../config.json';
import pixelPupAids from '../pixel-pup-aids.json';
import goodboiAids from '../goodbois-aids.json';
import { fetchAssetsZipped } from '../algod.js';
import { pupUnitToGoodboiUnit } from '../util.js';

const BOIS_CREATOR = config.bois.address;

const AssetsContext = createContext({});

export default AssetsContext;

export function AssetsProvider({ children }) {
  const [myPups, setMyPups] = useState(null); // TODO on disconnect clear my pups
  const [creatorBois, setCreatorBois] = useState({});
  const [error, setError] = useState(null);

  const fetchCreatorBois = useCallback(() => {
    setCreatorBois(null);
    setError(null);
    (async () => {
      try {
        setCreatorBois(await fetchAssetsZipped(BOIS_CREATOR, goodboiAids));
      } catch(e) {
        const message = `Error getting goodboi assets: ${e.message}`;
        console.error(message, e.stack);
        setError(prevError => prevError ?? message);
      }
    })();
  }, []);

  const fetchMyPups = useCallback((address) => {
    setMyPups(null);
    setError(null);
    if (address) {
      (async () => {
        try {
          const myPups = await fetchAssetsZipped(address, pixelPupAids);
          setMyPups(myPups);
          fetchCreatorBois();
        } catch(e) {
          const message = `Error getting your assets: ${e.message}`;
          console.error(message, e.stack);
          setError(prevError => prevError ?? message);
        }
      })();
    }
  }, [fetchCreatorBois]);

  useEffect(() => {
    if (creatorBois && myPups) {
      let hasChange = false;
      Object.values(myPups).forEach((myPup) => {
        const { unit } = myPup;
        const goodboiUnit = pupUnitToGoodboiUnit(unit);
        const goodboiIsAvalable = Object.entries(creatorBois ?? {})
          .some(([aid, { unit }]) => unit === goodboiUnit);
        if (myPup.mintable !== goodboiIsAvalable) {
          myPup.mintable = goodboiIsAvalable;
          hasChange = true;
        }
      })
      if (hasChange) {
        setMyPups({ ...myPups });
      }
    }
  }, [creatorBois, myPups, setMyPups]);

  const value = useMemo(() => ({
    error, myPups, creatorBois, fetchMyPups, fetchCreatorBois,
  }), [myPups, creatorBois, fetchMyPups, fetchCreatorBois, error, ]);

  return <AssetsContext.Provider value={value}>
    {children}
  </AssetsContext.Provider>;
}
