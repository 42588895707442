import { useMemo } from 'react';
import { useWallet } from '@txnlab/use-wallet';
import { useNavigate, useLocation } from "react-router-dom";
import useResponsive from '../hooks/use-responsive.js';
import { Button, Icon, Popup, } from 'semantic-ui-react'
import { VFlex, HFlex } from '../components/VFlex.jsx';
import LoginButton from '../components/LoginButton.jsx';

export default function Header() {
  const { activeAccount } = useWallet();
  const navigate = useNavigate();
  const [, size] = useResponsive();
  const location = useLocation();
  const [isMp, isPp] = useMemo(() => {
    return [
      { basic: location.pathname.startsWith('/my-pups') ? false : true },
      { basic: location.pathname.startsWith('/pixel-pedia') ? false : true }
    ]
  }, [location.pathname]);
  const small = size === 's';
  return <VFlex style={{flexDirection: 'row', padding: '10px', }}>
    <img alt="pixel pup logo" src="/assets/images/logo-maybe-2.webp" height="48" onClick={() => navigate('/')} className="fakelink header-logo" />
    <HFlex style={{width: '100%',}}>
      <Popup disabled={!small} content={<><Icon name="heart"/>MY PUPS</>} position="bottom center"  trigger={<Button {...(!small ? {content: "MY PUPS"} : null )} style={{marginRight: '10px'}} {...isMp} icon="heart" color={ activeAccount?.address ? 'pink' : 'grey' } onClick={() => navigate('/my-pups')} />} /> 
      <Popup disabled={!small} content={<><Icon name="book"/>PIXELPEDIA</>} position="bottom center" trigger={<Button {...(!small ? {content: "PIXELPEDIA"} : null )} basic {...isPp} icon="book" color="blue" onClick={() => navigate('/pixel-pedia')} />} />
    </HFlex>
    <LoginButton />
  </VFlex>
}
