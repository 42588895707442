import { useWallet } from '@txnlab/use-wallet';
import { useEffect, useCallback, useContext, useMemo, } from 'react';
import Backgrounds from '../backgrounds.json';
import OverlayContext from '../providers/Overlay.js';
import AssetsContext  from '../providers/Assets.js';
import Pup from '../components/pup.jsx';
import PupGrid from '../components/pup-grid.jsx';
import LoginButton from '../components/LoginButton.jsx';
import { VFlex } from '../components/VFlex.jsx';
import InfoModal from '../components/InfoModal.jsx';
import useResponsive from '../hooks/use-responsive.js';

export default function MyPups() {
  const { activeAccount } = useWallet();
  const { myPups, fetchMyPups } = useContext(AssetsContext);
  const { setOverlay, setPos, setColor, } = useContext(OverlayContext);
  const [, size] = useResponsive();

  useEffect(() => fetchMyPups(activeAccount?.address), [activeAccount?.address, fetchMyPups]);

  const { num, mintable } = useMemo(() => {
    return Object.values(myPups ?? {}).reduce(
      (out, { mintable }) => {
        out.num++;
        if (mintable === undefined)
          out.mintable = undefined;
        else if (mintable)
          out.mintable++;
        return out;
      }, { num: 0, mintable: 0 }
    );
  }, [myPups]);

  const closeOverlay = useCallback(() => setOverlay(), [setOverlay]);

  const infoCallback = useCallback(() => {
    window.scrollTo(0, 1500);
    setColor('#' + Backgrounds['Sky Blue']);
    setOverlay(<InfoModal color="blue" close={closeOverlay} />);
    setPos('bottom');
  }, [setOverlay, setPos, setColor]);

  return activeAccount?.address ?
    <><VFlex>
      <h2 style={{fontSize: '25pt', marginTop: "10px",}}>My Pixel Pups</h2>
      { !myPups ? <>Loading</> : <>
      <p style={{maxWidth: '600px', position: 'relative'}}>
        You have {num > 0 ? num : 'no'} pixel pup{num > 1 || num == 0 ? 's':''}{num === 0 ? '. :[' : '.'}<br />
      {mintable !== undefined && mintable ? <>{mintable} pup{mintable > 1 ? 's':''} can be pupgraded! <br/> <span onClick={infoCallback} className="afakelink">LEARN MORE</span><div className="mintable-badge abs-left-middle"></div></> : null}
      </p>
      {num === 0 ? <p>You can buy some on <a rel="noreferrer" target="_blank" href="https://www.nftexplorer.app/sellers/pixel-pups">secondary markets.</a></p> : null }
      </> }
      <PupGrid>
        { Object.entries(myPups ?? {}).map(([aid, props], i) => <Pup key={`${i}`} {...props} showMintable={true} show={size === 'l' ? 'flash' : null} width="100px" className="fakelink" />) }
      </PupGrid>
    </VFlex>
    <VFlex style={{flexGrow: 1}}>
    </VFlex></>
    : <VFlex>
      <h2 style={{textAlign: 'center', marginBottom: "50px"}}>Are you part of the pack?</h2>
      <LoginButton color="orange" className="rainbow" size="huge" label="CONNECT WALLET"/>
      <p style={{marginTop: '50px'}}>Connect to see your pups</p>
    </VFlex>
}
