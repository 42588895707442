import { useState, useCallback, useMemo, useEffect, } from 'react';
import { useWallet } from '@txnlab/use-wallet';
import { HFlex, VFlex } from '../components/VFlex.jsx';
import { Button, Header, Modal, } from 'semantic-ui-react';
import config from '../config.json';
import { decode } from 'algo-msgpack-with-bigint';
import { encodeUnsignedTransaction, Transaction, } from 'algosdk';
import { ALGO } from '../util.js';
import Pup from '../components/pup.jsx';
import Loader from "react-spinners/PuffLoader";
import CircleLoader from "react-spinners/ScaleLoader";
import Copyable from '../components/copyable.jsx';
import useResponsive from '../hooks/use-responsive.js';
import { getNextPrice, getPrice } from '../util.js';
import Countdown from '../components/countdown.jsx';

const txnEndpoint = config.backend.url + '/v2/mint/from-pup/';

const DONE_STEP = 5;

export default function PupgradeModal({ assetId, reloadData, bg, pupNum: num, close, goodboiAssetId }) {
  const { activeAccount, signTransactions, sendTransactions, } = useWallet();
  const [width] = useResponsive();
  const [error, setError] = useState();
  const [step, setStep] = useState(1);
  const [updateTime, setUpdateTime] = useState(Math.floor(Date.now() / 60_000));
  const price = useMemo(() => {
    let p = getPrice();
    if (!p && activeAccount?.address === 'JPEGRZ6G4IBZCOC7UV6QZWJ6TENNKRIPENUJTLG5K7PKIKMVTJHUGERARE') {
      p = 29000000;
    }
    return p;
  }, [updateTime, activeAccount?.address]);
  const showPrice = useMemo(() => (price / 1e6).toLocaleString(), [price]);
  const [nextTime] = useMemo(() => getNextPrice(price), [price]);
  const [encoded, setEncoded] = useState();

  const reloadPrice = useCallback(() => setUpdateTime(Date.now()), []);

  useEffect(() => {
    if (activeAccount?.address && assetId && price) {
      (async() => {
        try {
          const response = await fetch(txnEndpoint + assetId, {
            method: 'POST', 
            body: `for=${activeAccount.address}`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            }
          });
          if (!response.ok) {
            throw new Error(await response.text());
          }
          const encoded = await response.arrayBuffer();
          setEncoded(encoded);
          setStep(2);
        } catch(e) {
          setError(`Error getting transactions from backend: ${e.message}`);
          console.error(e);
        }
      })()
    }
  }, [assetId, price, activeAccount?.address]);


  const getTxns = useCallback(() => {
    (async() => {
      try {
        const [pay, optin, axfer, axconf] = decode(encoded);
        const { signed: signedX, ...unsignedAxfer } = axfer;
        const { signed: signedC, ...unsignedAxconf } = axconf;
        const txnsToSign = [pay, optin, unsignedAxfer, unsignedAxconf].map(t => encodeUnsignedTransaction(Transaction.from_obj_for_encoding(t)));
        setStep(3);
        const txns = await signTransactions(txnsToSign);
        txns[2] = signedX;
        txns[3] = signedC;
        setStep(4);
        await sendTransactions(txns, 6);
        setStep(5);
      } catch(e) {
        console.error(e);
        setError(e.message);
      }
    })()
  }, [encoded, signTransactions]);

  const [finalMessage, setFinalMessage] = useState('Celebrating!');
  useEffect(() => {
    let timeout;
    if (step === 5) {
      setTimeout(() => reloadData(), 500);
      timeout = setTimeout(() => {
        setFinalMessage(<>Enjoy your boi! <Button color="black" basic style={{margin: '-10px', marginLeft: '10px'}} size="tiny" onClick={close}>Close</Button></>);
        setStep(6);
      }, 8800); // 8300 exact?
    }
    return () => clearTimeout(timeout);
  }, [step, close, reloadData,]);

  const [errorExplainer, setErrorExplainer] = useState();
  useEffect(() => {
    if (error) {
      if (error.includes('overspend (account')) {
        setErrorExplainer('Insufficient Balance');
      } else if (error.includes('underflow on subtracting 1 from sender amount 0')) {
        setErrorExplainer('Already pupgraded!');
      } else {
        setErrorExplainer();
      }
    }
  }, [error]);

  const pupWidth = useMemo(() => Math.min(500, width), [width]);

  return <VFlex>
    <Header style={{marginTop: '3px', alignSelf: 'center', display: 'flex', justifyContent: 'center', width: 'calc(100% - 45px)'}} icon={"rocket"} content={ step <= 2 ? `READY to PUPGRADE?` : `PUPGRADING #${num}` } />
    <Button icon="close" style={{position: 'absolute', top: '10px', right: '10px', backgroundColor: 'rgba(255,255,255,0.45)'}} onClick={close} />
    <div className="modalgradient"></div>
    <VFlex>
      <Pup width={pupWidth} unit={`PP${num.padStart(3, '0')}`} style={{borderRadius: '25px'}} outerStyle={{overflow: 'hidden'}} className={ step >= DONE_STEP ? 'zoominpup' : (step == 4 ? 'shake' : null)}>
        <img alt="goodboi" style={{display: step < 2 ? 'none' : 'block'}} src={`/assets/images/bois/GB${num.padStart(4, '0')}.png`} style={{borderRadius: '25px'}} className={ step >= DONE_STEP ? 'rainbowboi' : ''}/>
      </Pup>
      <VFlex style={{height: '175px'}} className={error ? 'grayscale' : ''}>
        {
          price ? (
        step <= 2 ? <>
            { step === 1 ? <HFlex style={{margin: '20px', fontSize: '1.5rem'}}><CircleLoader style={{marginRight: '5px'}} color="black" size="20px" /><div>LOADING</div></HFlex> : 
        <Button style={{marginTop: '4px', border: '1px rgba(0,0,0,0.1) solid'}} size="huge" className="pupgrade-btn" onClick={getTxns}>PUPGRADE</Button>
            }
          <p style={{marginBottom: '10px',}}>Your pup can mint <strong>a goodboi</strong><br />with the same traits for {showPrice} ALGO!</p>
        </> : <VFlex style={{alignItems: 'flex-start'}}>
          <Checkpoint currentStep={step} error={error} step={3}>Waiting for your signature</Checkpoint>
          <Checkpoint currentStep={step} error={error} step={4}>Executing pupgrade</Checkpoint>
          <Checkpoint currentStep={step} error={error} step={5}>{finalMessage}</Checkpoint>
          { error ? <div className="error">Error</div> : null }
        </VFlex>
          ) : <><p style={{color: 'red'}} className="fontbold">WEN WEN WEN</p><p align="center">Pupgrades open in:<br /><Countdown to={nextTime} onDone={reloadPrice} /></p></> }
      </VFlex>
    </VFlex>
    <Modal
      onClose={() => { setError(); close() }}
      open={!!error}
      closeIcon
    >
      <Modal.Header>Something went wrong :/</Modal.Header>
      <Modal.Content>
        { /* <Image size='medium' src='/images/avatar/large/rachel.png' wrapped /> */ }
          <Header>An error occurred while pupgrading:</Header>
        { errorExplainer ? <p style={{textAlign:'center'}}><strong>Diagnosis: {errorExplainer}</strong></p> : null }
          <code style={{paddingLeft: '30px'}}>
            {error}
          </code>
      </Modal.Content>
      <Modal.Actions>
        <Copyable value={error} text="COPY ERROR" style={{float: 'left'}} />
        <Button
          content="DISMISS"
          onClick={() => { setError(); close(); }}
        />
      </Modal.Actions>
    </Modal>
  </VFlex>
}

const errorIcon = ': [';

function Checkpoint({ currentStep, step, error, children, }) {
  const past = currentStep > step;
  const current = currentStep === step;
  const future = currentStep < step;
  const icon = past ? '✅' : (current ? '🔃' : '🕛');
  if (error && future) return null;
  return <div className={future ? 'faded progitem' : (current ? 'bold progitem' : 'progitem')}>
    <div className="txnicon">{ current ? (error ? errorIcon : <Loader color="black" size="24px" />) : icon}</div>
    {children}
  </div>
}


