import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useNavigate, useLocation, } from "react-router-dom";
import { Icon, Button, Header, Label, Dropdown, } from 'semantic-ui-react';
import AssetsContext  from '../providers/Assets.js';
import Pup from '../components/pup.jsx';
import { goodboiUnitToAid, pupUnitToGoodboiUnit, deserializeFilters, serializeFilters  } from '../util.js';
import { VFlex, HFlex } from '../components/VFlex.jsx';
import Backgrounds from '../backgrounds.json';
import PupGrid from '../components/pup-grid.jsx';
import useResponsive from '../hooks/use-responsive.js';
import OverlayContext from '../providers/Overlay.js';
import InfoModal from '../components/InfoModal.jsx';

import PupTraits from '../traits.json';
import AllTraits from '../all-traits.json';

const AllTraitKeys = Object.keys(AllTraits);

function random(min, max) {
  return Math.floor(min + (max-min) * Math.random());
}

function randomTrait() {
  const traitKey = AllTraitKeys[random(0, AllTraitKeys.length - 1)];
  const traitValues = AllTraits[traitKey];
  const value = traitValues[random(0, traitValues.length - 1)];
  return {[traitKey]: value};
}

export default function PixelPedia() {
  const [perPage, setPerPage] = useState(54);
  const navigate = useNavigate();
  const { creatorBois, fetchCreatorBois } = useContext(AssetsContext);
  const { hash = "" } = useLocation();
  const [counter, setCounter] = useState(0);
  const [page, setPage] = useState(0);
  const [total,  setTotal] = useState(0);
  const { setOverlay, setPos, setColor, } = useContext(OverlayContext);

  const filters = useMemo(() => deserializeFilters(hash.slice(1)), [hash]);
  const setFilters = useCallback((filters) => {
    setPage(0);
    navigate(`#${serializeFilters(filters)}`)
  }, [navigate]);

  const [, size] = useResponsive();

  useEffect(() => {
    setPerPage(size === 's' ? 20 : 54);
  }, [size]) 

  useEffect(() => {
    if (!counter && !hash) {
      setFilters(randomTrait());
      setCounter(1);
    } else if (!counter) {
      setCounter(1);
    }
  }, [hash, counter, setFilters, ]);

  const prevPage = useCallback(() => setPage(p => p - 1), [setPage]);
  const nextPage = useCallback(() => setPage(p => p + 1), [setPage]);

  const [pups, setPups] = useState([]);

  useEffect(() => fetchCreatorBois(), [fetchCreatorBois]);

  const infoCallback = useCallback(() => {
    window.scrollTo(0, 1500);
    setColor('#' + Backgrounds['Red']);
    setOverlay(<InfoModal color="red" close={closeOverlay} />);
    setPos('bottom');
  }, [setOverlay, setPos, setColor]);

  const closeOverlay = useCallback(() => setOverlay(), [setOverlay]);

  useEffect(() => {
    const matchingPups = Object.entries(PupTraits)
      .filter(([unit, traits]) => {
        return Object.entries(filters).every(
          ([key, value]) => traits[key] === value
        )
      });
    setTotal(matchingPups.length);
    const outPups = matchingPups
      .slice(page * perPage, (page+1) * perPage)
      .map(([unit, traits]) => {
        let mintable;
        if (creatorBois && Object.keys(creatorBois).length) {
          mintable = !! creatorBois[goodboiUnitToAid(pupUnitToGoodboiUnit(unit))];
        }
        return { unit, mintable, showMintable: true }
      });
    setPups(outPups);
  }, [page, filters, creatorBois, perPage]);

  const hasPrev = useMemo(() => page > 0, [page]);
  const hasMore = useMemo(() => (page + 1) * perPage < total, [page, perPage, total]);

  return <>
    <VFlex>
      <h2 style={{fontSize: '25pt', marginTop: "10px",}}>PixelPedia</h2>
      <p align="center" style={{maxWidth: '600px', position: 'relative', paddingLeft: '10px', paddingRight: '10px', marginBottom: '20px',}}>
        Filter pups by traits <Icon color="green" fitted name="plus" /> <br/> shuffle a random trait <Icon color="pink" name="shuffle" /><br/>
        <span onClick={infoCallback} className="afakelink">Pupgradeable</span> pups shown with <span className="inline-mintable-badge"></span>
      </p>
      <div>
        <Filters size={size} total={total} filters={filters} setFilters={setFilters} />
        <PupGrid style={{marginTop: '5px', marginBottom: '5px'}}>
          {pups.map((props, i) => <Pup className="fakelink" key={i} show="flash" {...props} width="100px" />)}
        </PupGrid>
        <HFlex>
          { hasPrev ? <Button style={{marginTop: '5px', alignSelf: 'center'}} onClick={prevPage}>PREVIOUS</Button> : null }
          { hasMore ?  <Button style={{marginTop: '5px', alignSelf: 'center'}} onClick={nextPage}>NEXT</Button> : null }
        </HFlex>
      </div>
    </VFlex>
    <VFlex style={{flexGrow: 1}}>
    </VFlex>
  </>;
}

export function FilterModal({ close, filters, setFilters }) {
  const [previewFilters, setPreviewFilters] = useState(filters);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [matching, setMatching] = useState(0);

  useEffect(() => setPreviewFilters(filters), [filters]);

  useEffect(() => {
    const matchingPups = Object.entries(PupTraits)
      .filter(([unit, traits]) => {
        return Object.entries(previewFilters).every(
          ([key, value]) => traits[key] === value
        )
      })
    setMatching(matchingPups.length);
    if (matchingPups.length <= 1) {
      setAvailableFilters();
      return
    }
    const availableTraits = matchingPups.reduce((out, [_, traits]) => {
      for(const [key, value] of Object.entries(traits)) {
        if (previewFilters[key])
          continue;
        if (!out[key])
          out[key] = [];
        if (!out[key].includes(value))
          out[key].push(value);
      }
      return out;
    }, {});
    setAvailableFilters(availableTraits);
  }, [previewFilters]);

  const removeFilter = useCallback(
    (_key, _val) => setPreviewFilters(filters =>
      Object.fromEntries(Object.entries(filters).filter(([key, val]) => !(key === _key && val === _val) ))
    ), [setPreviewFilters]);

  const doClose = useCallback(() => {
    close();
    setFilters(previewFilters);
  }, [previewFilters, close, setFilters]);

  return <VFlex>
    <Header style={{marginTop: '3px', alignSelf: 'center', width: 'calc(100% - 90px)', display: 'flex', justifyContent: 'center',}} content='trait Filters' />
    <Button icon="close" basic style={{position: 'absolute', top: '10px', right: '10px',}} onClick={doClose} />
    <div className="modalgradient"></div>
    { availableFilters ? <>
    <h4 style={{marginTop: '10px'}}>AVAILABLE</h4>
    { Object.entries(availableFilters)
        .filter(([_, values]) => values?.length > 1)
        .map(([key, values], i) =>
          <Dropdown
            selectOnBlur={false}
            key={i}
            placeholder={key}
            onChange={(_, {value: [k,v]}) => {
              setPreviewFilters({...previewFilters, [k]: v})
            }}
            search
            selection
            options={values.map(v => ({text:v, value:[key,v]}))}
            style={{marginBottom: '3px'}}
          />
      ) }
    </> : null }
    <h4>ACTIVE</h4>
    { Object.entries(previewFilters).map(([key, val], i) =>
    <Pill key={`Pi${i}`} label={key} onClose={() => removeFilter(key, val)}>{val}</Pill>
    )}
    <p style={{marginTop: '10px'}}>Matching: {matching}</p>
    <VFlex style={{marginTop: '30px'}}>
      <Button labelPosition='left' icon="checkmark" color="green" content="APPLY" onClick={doClose}/>
      <Button labelPosition='left' style={{marginTop: '5px'}} icon="close" basic onClick={close} content="CANCEL" />
    </VFlex>
  </VFlex>
}


function Filters({ filters, setFilters, size, total }) {
  const { setOverlay, setPos, setColor, } = useContext(OverlayContext);

  const showOverlay = useCallback(() => {
    setPos('left');
    setColor('#' + Backgrounds['Orange']);
    setOverlay(<FilterModal filters={filters} setFilters={setFilters} close={() => setOverlay()} />);
  }, [filters, setFilters, setColor, setOverlay, setPos]);

  const randomFilter = useCallback(() => {
    setFilters(randomTrait())
  }, [setFilters]);

  const removeFilter = useCallback(
    (_key, _val) => {
      const nextVal = Object.fromEntries(Object.entries(filters).filter(([key, val]) => !(key === _key && val === _val)));
      setFilters(nextVal);
    }, [filters, setFilters]);

  const label = size === 's' ? 'TRAITS:' : (total === 1000 ?  'FILTER TRAITS' : 'SHOWING TRAITS:');

  return <HFlex style={{alignItems: 'flex-start'}}>
    <span style={{marginRight: '4px', marginTop: '3px', display: 'inline-flex',}}>{label}</span>
    <HFlex className="pill-container">
      {Object.entries(filters).map(([key, val], i) => <Pill key={`Pi${i}`} label={key} onClose={() => removeFilter(key, val)}>{val}</Pill>)}
    </HFlex>
    <Label color="green" className="fakelink" onClick={() => showOverlay()}><Icon fitted name="plus"/></Label>
    <Label title="Choose random filter" color="pink" className="fakelink" onClick={randomFilter}><Icon fitted name="random"/></Label>
  </HFlex>;
}

function Pill({label, children, onClose, style: s}) {
  const style = { marginBottom: '3px', ...s };
  return <>
    <Label color="blue" style={style}>
      <span className="fontbold">{label}</span>
      { children ? <Label.Detail color="red">{children}</Label.Detail> : null }
      <Icon name='delete' onClick={onClose} />
    </Label>
  </>
}
