import { useState, useEffect } from 'react';
import { lookupNFD } from '../util.js';

export default function useNFD(address) {
  const [NFD, setNFD] = useState();
  useEffect(() => {
    if (address)
      (async () => {
        const resp = await lookupNFD(address);
        if (resp && resp[address]) {
          setNFD(resp[address]);
        }
      })();
    else
      setNFD();
  }, [address]);
  return NFD;
}
