import './App.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { OverlayProvider } from './providers/Overlay.js';
import 'semantic-ui-css/semantic.min.css'
import WalletProvider from './components/WalletProvider.jsx';
import PupPage from './pages/pup.jsx';
import { AssetsProvider } from './providers/Assets.js';
import MyPups from './pages/my-pups.jsx';
import PixelPedia from './pages/pixel-pedia.jsx';
import Home from './pages/home.jsx';
import Layout from './components/Layout.jsx';

function App() {
  return (
    <OverlayProvider>
      <WalletProvider>
        <AssetsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact={true} element={<Home />} />
              <Route path="/" element={<Layout />}>
                <Route path="/my-pups" element={<MyPups />} />
                <Route path="/pixel-pedia" element={<PixelPedia />} />
                <Route path="/pup/:unit" element={<PupPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AssetsProvider>
      </WalletProvider>
    </OverlayProvider>
  );
}

export default App;
