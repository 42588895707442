import { HFlex } from './VFlex.jsx';

export default function Footer() {
  return <HFlex style={{marginTop: '5px', marginBottom: '5px',}}>
    <a rel="noreferrer" className="footerlink" href="https://pyreneespack.com/shop/" target="_blank" style={{marginRight: "15px"}}>MERCH</a>
    <a rel="noreferrer" className="footerlink" href="https://discord.gg/tshsC5V84w" target="_blank" style={{marginRight: "15px"}}>DISCORD</a>
    <a rel="noreferrer" className="footerlink" href="https://twitter.com/PyreneesPack/" target="_blank" style={{marginRight: "15px"}}>TWITTER</a>
    <a rel="noreferrer" className="footerlink" href="https://pyreneespack.com/" target="_blank">SITE</a>
  </HFlex>;
}

